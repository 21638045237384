<script setup>
import { ref, onMounted, watch, nextTick } from 'vue';
import uPlot from 'uPlot';
import 'uplot/dist/uPlot.min.css';

import { settingsStore } from "@/stores/SettingsStore.js";
import { appStateStore } from "@/stores/AppStateStore.js";
import { tracksStore } from "@/stores/TracksStore.js";
import { pointsStore } from "@/stores/PointsStore.js";
import PausePill from '@/MapComponentsLayers/PausePill.vue';

import { renderHeight, renderSpeed } from '../Helpers.js';

const props = defineProps(['timezone']);

const settings = settingsStore();
const app = appStateStore();
const tracks = tracksStore();
const points = pointsStore();

const uplotDom = ref(null); // holds the dom element for the chart
const tippyChartRef = ref(null);

const resizeChart = ref(true);

var uplotInstance = null;
var oldMin = null;
var oldMax = null;
var xPosition = null;
var trail = [];

var shadedRegions = ref([]);

const chartDataLength = ref(0);
const editDataLength = ref(false);


var hoveringTimer = null; // keep track if we have finished hovering or not

import { XMarkIcon, ChevronDownIcon, Bars3Icon, ExclamationTriangleIcon, ChevronDoubleLeftIcon, ChevronDoubleRightIcon, LockClosedIcon } from "@heroicons/vue/24/outline";


watch(() => settings.useMovingAverage, (currentValue, oldValue) => {
	app.restartLoop = true;
});
// watch(() => settings.useTrackFilter, (currentValue, oldValue) => {
// 	app.restartLoop = true;
// });


watch(() => settings.altitudeChartSize, (currentValue, oldValue) => {
	//console.log('setting size of chart to ' + settings.altitudeChartSize);
	nextTick(() => {
		setSize();
	});
});

watch(() => settings.selectedPanelWidth, (currentValue, oldValue) => {
	//console.log('setting size of chart to ' + settings.altitudeChartSize);
	nextTick(() => {
		setSize();
	});
});

watch(() => chartDataLength, (currentValue, oldValue) => {
	//console.log('setting size of chart to ' + settings.altitudeChartSize);
	nextTick(() => {
		setSize();
	});
});
watch(() => editDataLength, (currentValue, oldValue) => {
	//console.log('setting size of chart to ' + settings.altitudeChartSize);
	nextTick(() => {
		setSize();
	});
});
watch(() => tracks.tracksLoading, (currentValue, oldValue) => {
	//console.log('setting size of chart to ' + settings.altitudeChartSize);
	nextTick(() => {
		setSize();
	});
});


watch(() => settings.yAxis, (currentValue, oldValue) => {
	loadData();
});


watch(() => settings.showChart, (currentValue, oldValue) => {
	// console.log('showing chart');
	resizeChart.value = true;
	app.restartLoop = true;
});

watch(() => app.viewTrackStart, (currentValue, oldValue) => {
	//console.log('viewTrackStart changed');
	resizeChart.value = true;
});


watch(() => tracks.tracksUpdated, (currentValue, oldValue) => {
	loadData();
});

watch(() => app.selected, (currentValue, oldValue) => {
	//console.log("Reload Chart");
	resizeChart.value = true;
	loadData();
});

onMounted(() => {
	createChart();
});


watch(() => app.selectedThermal, (currentValue, oldValue) => {

	clearRegions('thermals');

	if (currentValue!=null) {

		let colour = 'E39F5033';
		switch (app.selectedThermal.image) {
			case 1: colour = '#EEE85A55'; break;
			case 2: colour = '#AFCD5755'; break;
			case 3: colour = '#79BE9755'; break;
			case 4: colour = '#65C2EE55'; break;
			case 5: colour = '#61294E55'; break;
			case 6: colour = '#9C2E3A55'; break;
			case 7: colour = '#C66C4155'; break;
			case 8: colour = '#E39F5055'; break;
		}


		setShadedRegion('thermals', [
			[app.selectedThermal.start_ts, app.selectedThermal.max_ts ]
		], colour);
	}
	
});

function clearRegions(name=null)
{
	if (name!==null) {
		for (let i=0; i<shadedRegions.value.length; i++) {
			if (shadedRegions.value[i]?.name==name) {
				shadedRegions.value.splice(i, 1);
			}
		}
	} else {
		shadedRegions.value.splice(0);
	}
	
	setSize();
}


function setShadedRegion(name, data, colour)
{
	// update existing
	let obj = {'name':name, 'colour':colour, 'data':data};

	// update if required
	if (shadedRegions.value) {
		for (let i=0; i<shadedRegions.value.length; i++) {
			if (shadedRegions.value[i]?.name==name) {
				shadedRegions.value[i] = obj;
				setSize();
				return; // we are done
			}
		}
	}
	// add to the array if not existing
	shadedRegions.value.push(obj);
	setSize();
}

function inRegion(region, x) {
	for (let i=0; i<region.data.length; i++) {
		if (x > region.data[i][0] && x < region.data[i][1]) return true;
	}
	return false;
}

// how to use the shaded regions
// setShadedRegion('thermals', [
// 	[1712798838, 1712799228],
// 	[1712800236, 1712800467]
// ], '#FF0000CC');

// setShadedRegion('something', [
// 	[1712799083, 1712799443]
// ], '#00FF00CC');

// setClearRegions();

function scaleReset() {
  let lastIdx = uplotInstance.data[0].length - 1;

  uplotInstance.setScale('x', {
    min: uplotInstance.data[0][0],
    max: uplotInstance.data[0][lastIdx],
  });
}

function loadData() {
	// console.log('load data called');

	if (!uplotInstance) return;

	//console.log('updating chart');
	var chartData = tracks.getTrackForUPlot(app.selected, settings.yAxis);

	//console.log(chartData);
	if (chartData.length==3) {
		chartDataLength.value = chartData[0].length;
		if (chartData[0].length==0) {
			return; // don't continue if we have no data
		}
	} else {
		chartDataLength.value = 0;
		return; // don't continue if we have no data
	}
	
	//console.log(chartData);

	if (chartData.length==0) {
		// console.log('no data');
	}

	// console.log(uplotInstance.scales);
	// console.log(chartData);

	// if we're at the current extents, we can resize
	if ( uplotInstance?.scales?.x?.min==oldMin && uplotInstance?.scales?.x?.max==oldMax)
	{
		//console.log('resizing chart');
		resizeChart.value = true;
	}


	uplotInstance.setData(chartData, resizeChart.value);
	oldMin = chartData[0][0];
	oldMax = chartData[0][chartData[0].length-1];

	//uplotInstance.setScale('x', {oldMin, oldMax}); 
	// console.log('setting data');
	// console.log(oldMin + ' ' + oldMax);

	uplotInstance.redraw();

	if (resizeChart.value) scaleReset();

	nextTick(() => {
		setSize();
	});

}

function getTimezone() {
	if (settings.timezoneUnits=='utc') {
		return 'Etc/UTC';
	}
	if (settings.timezoneUnits=='local' && points.selected) {
		return points.selected.timezone;
	}
	return Intl.DateTimeFormat().resolvedOptions().timeZone;
}


function createChart() {

	// check if already created
	if (uplotInstance!=null) return false;
	

	let opts = {
		tzDate: ts => uPlot.tzDate(new Date(ts * 1e3), getTimezone()),
		id: "chart1",
		class: "my-chart",
		width: 800,
		height: settings.altitudeChartSize,
		pxAlign: false,
		cursor: {
			points: {
				fill: (u, sidx) => { return u.series[sidx].fill()},
				size: (u, sidx) => u.series[sidx].points.size,
			},
		},
		plugins: [
			touchZoomPlugin()
		],
		legend: {
			show: false,
		},
		series: [
			{},
			{
				// initial toggled state (optional)
				show: true,

				spanGaps: false,

				// in-legend display
				label: "Alt",
				//value: (self, rawValue) => "$" + rawValue.toFixed(2),

				// series style
				stroke: "white",
				width: 1,
				points: {
					size: 10,
					fill: '#1e1e1e'
				},
			},
			{
				show: true,
				label: "GL",
				fill: "rgba(100, 100, 100, 0.3)",
				stroke: "gray",
				width: 1
			}
		],
		axes: [
			{ // X axis time
				show: true,
				gap: 2,
				space: 40,
				size: 50,
				stroke: "white",
				grid: {
					show: true,
					stroke: "rgba(255, 255, 255, 0.1)",
					width: 1,
					dash: [],
				},
			},
			{ // Y axis, altitude
				show: true,
				gap: 2,
				size: 50,
				space: 15,
				stroke: "white",
				grid: {
					show: true,
					stroke: "rgba(255, 255, 255, 0.1)",
					width: 1,
					dash: [],
				},
			}
		],
		hooks: {
			setScale: [
				(u, key) => {
					if (key == 'x')
						// console.log("zoomed!");
						resizeChart.value = false;

						app.selectedRangeStart = Math.floor(u.scales.x.min);
						app.selectedRangeEnd = Math.ceil(u.scales.x.max);
				}
			],
			setCursor: [
				u => {

					const { left, top, idx } = u.cursor;
					//console.log(idx + ' ' + left + ' ' + top);
					if (idx) {
						const x = u.data[0][idx];
						const y = u.data[1][idx];
						if (idx>0) {
							xPosition = idx;
							app.currentHighlightTime = x;
							app.currentHighlightPos = xPosition;
							app.hoveringOnChart = true;

							// clearTimeout(hoveringTimer);
							// hoveringTimer = setTimeout(() => {
							// 	app.hoveringOnChart = false;
							// 	app.hoverPoint = null;
							// }, 2000);

							tracks.setHoverPointByPosition(app.selected, xPosition);
						}
					}
					
					
				}
			],
			drawAxes: [
				u => {

					if (!shadedRegions.value) return;

					let { ctx } = u;
					let { left, top, width, height } = u.bbox;


					let [ i0, i1 ] = u.series[0].idxs;

					//console.log(shadedRegions.value);

					ctx.save();

					// for each of our series
					for (let i=0; i<shadedRegions.value.length; i++) {

						ctx.strokeStyle = shadedRegions.value[i].colour;
						ctx.fillStyle = shadedRegions.value[i].colour;
						ctx.beginPath();

						let boxLeft=null;
						let boxWidth=null;

						for (let j = i0; j <= i1; j++) {
							let v = u.data[0][j];

							if (inRegion(shadedRegions.value[i], v)) {
								//console.log('is in: ' + v);
								if (boxLeft==null) {
									boxLeft = Math.round(u.valToPos(v, 'x', true));
									//console.log('Starting box at ' + v + ' ' + boxLeft);
								}
								
							} else {
								// if we had started, set the width
								if (boxLeft) {
									boxWidth = Math.round(u.valToPos(v, 'x', true)) - boxLeft;
									// draw the box
									ctx.rect(boxLeft, top, boxWidth, height); 
									boxLeft = null;
									boxWidth = null;
								}
							}
						}

						ctx.closePath();
						ctx.stroke();
						ctx.fill();
					}

					
					ctx.restore();
				},
			],
		}
	};

	// save the extents so we can compare them next time
	// oldMin = trail[0][0];
	// oldMax = trail[0][trail[0].length-1];
	uplotInstance = new uPlot(opts, trail, uplotDom.value);

	// set the size, and set up a listener
	
	setTimeout(() => {
		setSize();
	}, 500);
	window.addEventListener("resize", setSize);

	// check if we have data to display
	loadData();
}


function touchZoomPlugin(opts) {

	
	function init(u, opts, data) {
		let over = u.over;
		let rect, oxRange, oyRange, xVal, yVal;
		let fr = {x: 0, y: 0, dx: 0, dy: 0};
		let to = {x: 0, y: 0, dx: 0, dy: 0};

		function storePos(t, e) {
			let ts = e.touches;

			let t0 = ts[0];
			let t0x = t0.clientX - rect.left;
			let t0y = t0.clientY - rect.top;

			if (ts.length == 1) {
				t.x = t0x;
				t.y = t0y;
				t.d = t.dx = t.dy = 1;
			}
			else {
				let t1 = e.touches[1];
				let t1x = t1.clientX - rect.left;
				let t1y = t1.clientY - rect.top;

				let xMin = Math.min(t0x, t1x);
				let yMin = Math.min(t0y, t1y);
				let xMax = Math.max(t0x, t1x);
				let yMax = Math.max(t0y, t1y);

				// midpts
				t.y = (yMin+yMax)/2;
				t.x = (xMin+xMax)/2;

				t.dx = xMax - xMin;
				t.dy = yMax - yMin;

				// dist
				t.d = Math.sqrt(t.dx * t.dx + t.dy * t.dy);
			}
		}

		let rafPending = false;

		function zoom() {
			rafPending = false;

			let left = to.x;
			let top = to.y;

			// non-uniform scaling
		//	let xFactor = fr.dx / to.dx;
		//	let yFactor = fr.dy / to.dy;

			// uniform x/y scaling
			let xFactor = fr.d / to.d;
			let yFactor = fr.d / to.d;

			let leftPct = left/rect.width;
			let btmPct = 1 - top/rect.height;

			let nxRange = oxRange * xFactor;
			let nxMin = xVal - leftPct * nxRange;
			let nxMax = nxMin + nxRange;

			let nyRange = oyRange * yFactor;
			let nyMin = yVal - btmPct * nyRange;
			let nyMax = nyMin + nyRange;

			u.batch(() => {
				u.setScale("x", {
					min: nxMin,
					max: nxMax,
				});

				// // update selected range
				// app.selectedRangeStart = nxMin;
				// app.selectedRangeEnd = nxMax;
				app.selectedRangeStart = Math.floor(u.scales.x.min);
				app.selectedRangeEnd = Math.ceil(u.scales.x.max);

				// u.setScale("y", {
				// 	min: nyMin,
				// 	max: nyMax,
				// });
			});
		}

		function touchmove(e) {
			storePos(to, e);

			if (!rafPending) {
				rafPending = true;
				requestAnimationFrame(zoom);
			}
			e.preventDefault();
		}

		over.addEventListener('wheel', event => {
			const { ctrlKey } = event
			if (ctrlKey) {
				event.preventDefault();
				return
			}
		}, { passive: false })


		over.addEventListener("touchstart", function(e) {
			rect = over.getBoundingClientRect();

			storePos(fr, e);

			oxRange = u.scales.x.max - u.scales.x.min;
			oyRange = u.scales.y.max - u.scales.y.min;

			let left = fr.x;
			let top = fr.y;

			xVal = u.posToVal(left, "x");
			yVal = u.posToVal(top, "y");

			const xIndex = u.valToIdx(xVal);
			
			if (xIndex!==null) {
				app.currentHighlightPos = xIndex;
				app.currentHighlightTime = xVal;
				app.hoveringOnChart = true;
			}

			
			// clearTimeout(hoveringTimer);
			// hoveringTimer = setTimeout(() => {
			// 	app.hoveringOnChart = false;
			// 	app.hoverPoint = null;
			// }, 2000);

			tracks.setHoverPointByPosition(app.selected, xPosition);

			document.addEventListener("touchmove", touchmove, {passive: false});
		});

		over.addEventListener("touchend", function(e) {
			document.removeEventListener("touchmove", touchmove, {passive: false});
		});
	}

	return {
		hooks: {
			init
		}
	};
}




function setSize() {
	uplotInstance.setSize(getSize());
}

function getSize() {
	if (!uplotDom.value) return {width: 100, height: 100};
	//console.log(uplotDom.value.clientWidth);
	return {
		width: uplotDom.value.clientWidth,
		height: uplotDom.value.clientHeight,
	}
}

var trackLengthMenu = [
	//{label: '5 mins', age: 5, pro: false},
	{label: '10m', age: 10, pro: false},
	{label: '1h', age: 60, pro: false},
	{label: '6h', age: 360, pro: false},
	{label: '12h', age: 720, pro: false},
	{label: '24h', age: 1440, pro: false},
	{label: '2d', age: 2880, pro: true},
	{label: '3d', age: 4320, pro: true},
	{label: '4d', age: 5760, pro: true},
	{label: '6d', age: 8640, pro: true},
	//{label: 'All (Up to 48hr)', age: 2880, pro: false},
];
</script>



<template>

	<div class="relative sm:rounded-lg bg-gray-900  pointer-events-auto text-white">
		<div class="top-row flex items-center gap-2 w-full text-white ">
			
			<tippy ref="tippyChartRef" trigger="click" :interactive="true" theme="puretrack" placement="top" :distance="5" class="flex place-content-stretch self-stretch items-center  w-6 bg-gray-900 sm:rounded-tl-lg mx-2">

				<Bars3Icon class="size-6"></Bars3Icon>

				<template #content>

					<div class="text-black bg-white rounded pointer-events-auto flex flex-col">

						<div class="flex p-2">
							<div class="mr-2">Y Axis</div>
							<a class="mr-2 cursor-pointer border border-gray-800 px-1 rounded" :class="settings.yAxis=='altitude' ? 'bg-gray-800 text-white' : ' hover:bg-gray-200'" v-on:click.prevent="settings.yAxis='altitude'; tippyChartRef.hide()">Altitude</a>
							<a class="mr-2 cursor-pointer border border-gray-800 px-1 rounded" :class="settings.yAxis=='speed' ? 'bg-gray-800 text-white' : ' hover:bg-gray-200'" v-on:click.prevent="settings.yAxis='speed'; tippyChartRef.hide()">Speed</a>
						</div>


						<div class="px-2 py-1 hover:bg-gray-300 flex cursor-pointer" @click="settings.showChart=false">
							Hide Chart
						</div>


						<div class="px-2 py-1 hover:bg-gray-300 flex cursor-pointer" @click="editDataLength=true; tippyChartRef.hide()">
							Edit Chart Data Length
						</div>
	 

						<label for="usemovingaverage" class="px-2 py-1 hover:bg-gray-300 flex cursor-pointer items-center gap-1">
							<input id="usemovingaverage" type="checkbox" v-model="settings.useMovingAverage" :value="true"> {{ $t('chart.use-moving-average') }}
						</label>

						<label for="useTrackFilter" class="px-2 py-1 hover:bg-gray-300 flex cursor-pointer items-center gap-1">
							<input id="useTrackFilter" type="checkbox" v-model="settings.useTrackFilter" :value="true"> {{ $t('chart.filter-bad-data') }}
						</label>

						<div class="flex p-2">
							<a class="mr-2 cursor-pointer border border-gray-800 px-1 rounded" :class="settings.altitudeChartSize==200 ? 'bg-gray-800 text-white' : ' hover:bg-gray-200'" v-on:click.prevent="settings.altitudeChartSize=200; tippyChartRef.hide()">Big</a>
							<a class="mr-2 cursor-pointer border border-gray-800 px-1 rounded" :class="settings.altitudeChartSize==150 ? 'bg-gray-800 text-white' : ' hover:bg-gray-200'" v-on:click.prevent="settings.altitudeChartSize=150; tippyChartRef.hide()">Medium</a>
							<a class="mr-2 cursor-pointer border border-gray-800 px-1 rounded" :class="settings.altitudeChartSize==100 ? 'bg-gray-800 text-white' : ' hover:bg-gray-200'" v-on:click.prevent="settings.altitudeChartSize=100; tippyChartRef.hide()">Small</a>
						</div>

					</div>

				</template>
			</tippy>

			<div v-for="item in trackLengthMenu" :key="item.id" v-show="item.age==settings.trackLength" class="text-sm bg-gray-200  rounded-full px-2 cursor-pointer"
				:class="editDataLength ? 'bg-lime-500 text-white' : 'bg-gray-200 text-gray-900'"
				@click="editDataLength=!editDataLength">
				{{ item.label }}
			</div>
			

			<div class="flex-grow text-sm  flex items-center gap-4 justify-center" >
				<div v-if="app.hoverPoint && !isNaN(app.hoverPoint?.alt)">
					<span class="text-xs text-gray-400 dark:text-gray-600">Alt</span>
					{{renderHeight(app.hoverPoint.alt, settings.altitudeUnits)}}
				</div>
				<div v-if="app.hoverPoint && !isNaN(app.hoverPoint?.gl) && !isNaN(app.hoverPoint?.alt)">
					<span class="text-xs text-gray-400 dark:text-gray-600">AGL</span>
					{{renderHeight(app.hoverPoint.alt - app.hoverPoint.gl, settings.altitudeUnits)}}
				</div>
				<div v-if="app.hoverPoint && !isNaN(app.hoverPoint?.speed)">
					
					{{renderSpeed(app.hoverPoint.speed, settings.speedUnits, app.hoverPoint.speed>10 ? 0:1 )}}
				</div>
			</div>


			<PausePill opacity="60" :small="true" :seconds="true"></PausePill>

			<XMarkIcon class="size-6 mr-2" 
			@click="settings.showChart=false"></XMarkIcon>

		</div>

		<div v-if="tracks.tracksLoading>0" class="absolute w-full z-50">
			<LoadingHorizontal v-if="tracks.tracksLoading>0" class="mx-auto"></LoadingHorizontal>
		</div>


		<div v-show="chartDataLength>0 && editDataLength==false" class="">
			<div class="w-full px-0 py-0" id="uplotDom" ref="uplotDom" :style="'height:' + settings.altitudeChartSize + 'px;'"></div>
		</div>



		<div :style="'height:' + settings.altitudeChartSize + 'px;'" 
		class="w-full text-center text-sm gap-2 flex flex-col items-center px-2 "
		v-if="chartDataLength==0 || editDataLength==true">

			<div v-if="chartDataLength==0 || editDataLength" class="w-full px-2 pt-4 text-center text-gray-400 text-sm gap-2 flex flex-col">

				<div v-if="chartDataLength==0 && editDataLength==false" class="">
					{{ $t('chart.no-track-data') }} 
					<button class="link" @click="editDataLength=true;">
						{{ $t('chart.edit-chart-data-range') }}
					</button>
				</div>

				<div class="flex flex-wrap justify-center gap-2 items-center " v-if="editDataLength">

					<div>{{ $t('layers-menu.load-track-back-to') }}</div>

					<div class="flex flex-wrap justify-center gap-1">
						<button v-for="item in trackLengthMenu"
							:key="item.id"
							class="border border-lime-600 dark:border-lime-500 py-1 px-1 rounded-lg cursor-pointer disabled:opacity-50 flex items-center gap-1"
							:disabled="!app.subscribed && item.pro"
							@click="settings.trackLength = item.age; editDataLength=false;"
							:class="settings.trackLength==item.age ? 'bg-lime-600 dark:bg-lime-500 text-white dark:text-black' : 'hover:bg-gray-200 dark:hover:bg-gray-900'">
								{{ item.label }}
								<LockClosedIcon class="h-4 w-4" v-show="!app.subscribed && item.pro" />
						</button>
					</div>

					<div @click="editDataLength = false">{{ $t('app.cancel') }}</div>

				</div>
			</div>
		</div>

	</div>


</template>


<style>
.u-select {
	background: rgba(255,255,255,0.1) !important;
	position: absolute !important;
	pointer-events: none !important;
}

.u-hz .u-cursor-x,
.u-vt .u-cursor-y {
	height: 100% !important;
	border-right: 2px solid #607D8B !important;
}

.u-hz .u-cursor-y,
.u-vt .u-cursor-x {
	width: 100% !important;
	border-bottom: none !important;
}
.u-cursor-pt {
	background-color: rgba(255,255,255,1) !important;
}
</style>

