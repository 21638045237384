import { defineStore, acceptHMRUpdate } from 'pinia';
import { ref, computed, watch } from 'vue';
import { metersToFeet, limitStringStart, limitStringEnd, renderHeight, renderSpeed, pointShortName } from '../Helpers.js';

import { settingsStore } from "@/stores/SettingsStore.js";
import { appStateStore } from "@/stores/AppStateStore.js";
import { messagesStore } from "@/stores/MessagesStore.js";

export const zonesStore = defineStore('zones', () => {

	const zones = ref(null); // main list of zones
	const messages = messagesStore();
	const app = appStateStore();

	const newZone = ref(null); // a new zone if we are adding one
	const added = ref(false);

	clearNew();


	function clearNew() {
		newZone.value = {
			lat: null,
			long: null,
			radius: null,
			radiusRaw: 2,
			name: null
		}
	}

	function clear() {
		if (zones.value!=null) {
			//console.log('clearing zones');
			zones.value.splice(0);
		}
	}

	function deleteZone(zone) {

		axios.delete('/api/zones/' + zone.id).then(function(response) {

			messages.success('Zone Deleted');
			load(); // update list of zones

		}).catch(function (error) {
			console.log('Delete Error:');
			console.log(error);
		});
	}


	function load() {

		if (!app.subscribed) return;

		axios.get('/api/zones').then(function(response) {

			zones.value = response.data.data;

		}).catch(function (error) {
			console.log('Loading Error:');
			console.log(error);
		});
	}


	function add() {

		if (newZone.value.name==null || newZone.value.name=='') {
			messages.error('A name is required');
		}

		axios.post('/api/zones', newZone.value).then(function(response) {

			//console.log(response.data.data);

			messages.success('Zone Added');
			clearNew(); // reset new zone
			load(); // update list of zones
			console.log('got here!');

			added.value=true;

		}).catch(function (error) {
			console.log('saving error:');
			console.log(error.response.data);
			messages.error(error.response.data.message);
		});

	}

	return {
		zones,
		newZone,
		clearNew,
		add,
		load,
		deleteZone,
		clear,
		added,
	}

});


// make sure to pass the right store definition, `useAuth` in this case.
if (import.meta.hot) {
	import.meta.hot.accept(acceptHMRUpdate(zonesStore, import.meta.hot))
}