import { defineStore, acceptHMRUpdate } from 'pinia';
import { ref } from 'vue';

export const recentAircraftStore = defineStore('recentAircraftSearches', () => {

	const recent = ref([]);

	function clearRecent() {
		recent.value.splice(0);
	}

	function addToHistory(doc) {

		// console.log('adding history item ');
		// console.log(doc);


		var result = recent.value.filter(obj => {
			return obj.id === doc.id
		})
		// console.log(result);
		if (result.length==0) recent.value.unshift(doc);

		// check if we've got too many items, and delete the last
		if (recent.value.length>6) recent.value.pop();
	}

	return {
		recent,
		addToHistory,
		clearRecent
	};
},
{
	persist: {
		key: 'search-results',
		// afterRestore: (ctx) => {
		// 	console.log(`just restored '${ctx.store.$id}'`)
		// }
	},
});


// make sure to pass the right store definition, `useAuth` in this case.
if (import.meta.hot) {
	import.meta.hot.accept(acceptHMRUpdate(recentAircraftStore, import.meta.hot))
}