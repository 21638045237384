import { watch, ref } from 'vue';

import { zonesStore } from "../stores/ZonesStore.js";
import { settingsStore } from "../stores/SettingsStore.js";
import { pointTypesStore } from "@/stores/PointTypesStore.js";
import { appStateStore } from "@/stores/AppStateStore.js";
import { useMapTools } from "@/Composables/MapTools.js";
import { distanceToMeters } from '../Helpers.js';

import circle from '@turf/circle';

export function useDrawZones() {

	const settings = settingsStore();
	const app = appStateStore();
	const zones = zonesStore();
	const newCircle = ref([]);

	
	let options = {steps: 40, units: 'meters'};


	// if the selected item changes, load the alerts for it
	watch(() => app.selected, (currentValue, oldValue) => {
		//console.log('usedrawzones selected changed');
		loadAlerts(currentValue);
	});


	function loadAlerts(key) {
		app.alerts.splice(); // empty array

		if (!app.user) return;

		if (!key) return;

		axios.get('/api/alerts/?allusers=1&key=' + key)
		.then(function(response) {
			app.alerts = response.data.data;
			//console.log(app.alerts);
		}).catch(function (error) {
			console.log(error);
		});
	}


	function getZoneShape(zone) {
		return circle([
			zone.location.coordinates[0],
			zone.location.coordinates[1]], 
			zone.radius, 
			options
		);
	}

	function getZoneShapes() {
		if (!zones.zones) return null;

		let shapes = [];
		for (let i=0; i<zones.zones.length; i++) {
			//console.log(zones.zones[i]);
			shapes.push(getZoneShape(zones.zones[i]));
		}
		return shapes;
	}


	function goToZone(zone) {
		var shape = getZoneShape(zone);
		var coords = shape.geometry.coordinates[0];
		var bounds = coords.reduce(function(bounds, coord) {
			return bounds.extend(coord);
		}, new app.mapbox.LngLatBounds(coords[0], coords[0]));
		app.map.fitBounds(bounds, {
			padding: 70
		});
	}


	watch(() => zones.newZone, (currentValue, oldValue) => {
		if (currentValue.lat==null) return;

		zones.newZone.radius = distanceToMeters(currentValue.radiusRaw, settings.distanceUnits);
		if (zones.newZone.radius<1 || zones.newZone.radius>100000) zones.newZone.radius = 2000;
		let shape = circle([zones.newZone.long,zones.newZone.lat], zones.newZone.radius, options);
		app.map.getSource('newZone').setData(shape);
	}, { deep: true });


	// create the geojson for the circles
	watch(() => zones.zones, (currentValue, oldValue) => {
		// console.log("zones changed");
		// console.log(currentValue);
		if (!app.map) return;
		
		// empty
		if (currentValue.length==0) {
			app.map.getSource('zones').setData({
				"type": "FeatureCollection",
				"features": []
			});
		}

		let shapes = getZoneShapes();
		if (shapes.length>0) app.map.getSource('zones').setData({
			"type": "FeatureCollection",
			"features": shapes
		});
		// console.log(shapes);
	}, { deep: true });



	function constructZoneLayers() {
		// console.log('Adding zone layer');

		let colour =  '#00A';

		app.map.addSource('zones', {
			'type': 'geojson',
			'data': {
				'type': 'FeatureCollection',
				'features': [],
			}
		});
		app.map.addLayer({
			'id': 'zonesFill',
			'type': 'fill',
			'source': 'zones',
			'layout': {
			},
			'paint': {
				'fill-color': colour,
				'fill-opacity': 0.1
			}
		},'zones-slot');
		app.map.addLayer({
			'id': 'zonesLine',
			'type': 'line',
			'source': 'zones',
			'layout': {
				'line-join': 'round',
				'line-cap': 'round'
			},
			'paint': {
				'line-color': colour,
				'line-width': 4,
			}
		},'zones-slot');
	}


	function constructNewZoneLayers() {

		let colour =  '#A00';

		app.map.addSource('newZone', {
			'type': 'geojson',
			'data': {
				'type': 'FeatureCollection',
				'features': [],
			}
		});
		app.map.addLayer({
			'id': 'newZoneFill',
			'type': 'fill',
			'source': 'newZone',
			'layout': {
			},
			'paint': {
				'fill-color': colour,
				'fill-opacity': 0.1
			}
		});
		app.map.addLayer({
			'id': 'newZoneLine',
			'type': 'line',
			'source': 'newZone',
			'layout': {
				'line-join': 'round',
				'line-cap': 'round'
			},
			'paint': {
				'line-color': colour,
				'line-width': 4,
			}
		});

	}

	function destructNewZoneLayers() {
		if (app.map.getLayer('newZoneFill')) app.map.removeLayer('newZoneFill');
		if (app.map.getLayer('newZoneLine')) app.map.removeLayer('newZoneLine');
		if (app.map.getSource('newZone')) app.map.removeSource('newZone');
	}

	function destructZoneLayers() {
		if (app.map.getLayer('zonesFill')) app.map.removeLayer('zonesFill');
		if (app.map.getLayer('zonesLine')) app.map.removeLayer('zonesLine');
		if (app.map.getSource('zones')) app.map.removeSource('zones');
	}

	// set the map data to the computed geoJSON
	//function updateTracks() {
		//console.log('updating tracks!');

		//map.getSource('tracks').setData(tracks.geoJSON());

	//}



	return {
		constructNewZoneLayers,
		destructNewZoneLayers,
		constructZoneLayers,
		destructZoneLayers,
		getZoneShapes,
		getZoneShape,
		goToZone,
		loadAlerts
	};
}
