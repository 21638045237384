<script setup>
import { defineAsyncComponent, ref, onMounted, watch } from 'vue';

import { appStateStore } from "@/stores/AppStateStore.js";
const app = appStateStore();
import { settingsStore } from "@/stores/SettingsStore.js";
const settings = settingsStore();

const props = defineProps(['opacity','small','seconds']);

import FastForwardIcon from '@/Icons/FastForwardIcon.vue';

import { formatTimeFromStamp } from '../Helpers.js';

</script>

<template>
	<div v-if="app.hoverPoint || app.paused" class="self-center">

		<div :title="$t('app.back-to-live')" @click="app.unpause()" 
		class="flex items-center gap-2 ui-pill px-1 py-1 bg-blue-800 text-white text-sm"
		:class="[ 
			props.opacity ? 'bg-opacity-'+props.opacity : ''
		]">

			<div class="ml-1" v-if="app.hoverPoint"
			:class="[ small ? 'hidden xs:block' : '']"
			>
				{{ formatTimeFromStamp(app.hoverPoint.stamp, false, props.seconds, app.hoverPoint.timezone, settings.timeUnits, settings.timezoneUnits) }}

			</div>
			<div class="ml-1" v-if="app.paused && !app.hoverPoint"
			:class="[ small ? 'hidden xs:block' : '']">
				{{ $t('app.paused') }}
			</div>

			<button  :title="$t('app.back-to-live')" 
				class="pill-button flex items-center justify-center p-1">
				<FastForwardIcon class="text-blue-800"
				:class="[ small ? 'size-3': 'size-4']"  />
			</button>
		</div>

		</div>
</template>